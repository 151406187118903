<template>
  <div>
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
      @ended="onPlayerEnded($event)"
      @timeupdate="onPlayerTimeupdate($event)"
    ></video-player>
    <div class="title">
      <div @click="type = 1" :class="type == 1 ? 'item active' : 'item'">
        视频内容列表
      </div>
      <div @click="type = 2" :class="type == 2 ? 'item active' : 'item'">
        实训考点
      </div>
    </div>
    <div class="list" v-show="type == 1">
      <div
        @click="tapVideo(item, index, 'no', 1)"
        :class="active == index ? 'item active' : 'item'"
        v-for="(item, index) in playerOptions.sources"
        :key="index"
      >
        <span
          class="wc icon iconfont icon-zhengque1 rote"
          v-if="item.iswatch == 1"
        ></span>
        {{ index + 1 }}.{{ item.title }}
      </div>
    </div>
    <div v-show="show && type == 1" class="loadmore" @click="loadmore">
      加载更多
    </div>
    <div class="list" v-show="type == 2">
      <div
        class="item"
        @click="setClick(index, item)"
        v-for="(item, index) in practicalList"
        :key="index"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="cameraImg" style="display: none">
      <!-- <div><video width="200px" height="150px" ref="video"></video></div> -->
      <!-- <button id="snap" @click="getImg">截取图像</button> -->
      <div style="display: none">
        <canvas width="200px" height="150px" ref="canvas"></canvas>
      </div>
    </div>
    <!-- 活跃度弹窗 -->
    <div class="mask1" v-show="flag">
      <div>
        <img class="imgs" src="../../assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">
            <span>亲！</span> 你还在学习吗<br /><span class="span"
              >如果在的话，请点我哦！</span
            >
          </div>
          <div class="xian"></div>
          <div class="dian" @click="normal">请点我</div>
        </div>
        <img
          class="img"
          src="../../assets/close.png"
          alt=""
          @click="flag = false"
        />
      </div>
    </div>
    <div class="mask" v-show="flagss">
      <div>
        <img class="imgs" src="../../assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">人脸识别认证</div>
          <div class="content1">
            在您的学习过程中我们将对您进行学习身份进行核验，届时会人脸识别的方式进行核验。
          </div>
          <el-checkbox style="margin-left: 30px" v-model="checked"
            >同意《职培通在线教育云平台学员核验规定》</el-checkbox
          >
          <div class="dian" @click="normal1">开始认证</div>
        </div>
      </div>
    </div>
    <div class="human_mask" v-show="flags">
      <div>
        <img class="imgs" src="../../assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">人脸识别</div>
          <video class="head" ref="video"></video>
          <div class="head_1"></div>
          <div class="dian" id="snap" @click="getImg">开始核验</div>
        </div>
      </div>
    </div>
    <!-- //时间停止计时警告 -->
    <div class="stop_mask" v-if="flag2">
      <div>
        <img class="imgs" src="../../assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">
            <span>亲！</span> 您当前页面学习时长已停止记录<br /><span
              class="span"
            >
            </span>
          </div>
          <div class="xian"></div>
          <div class="dian" @click="goon">点我继续计时</div>
        </div>
        <img class="img" @click="goon" src="../../assets/close.png" alt="" />
      </div>
    </div>
    <div style="height: 500px"></div>
    <el-dialog
      top="45vh"
      title="实训考点内容"
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <span v-html="ledgDeta.text"></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="getStatus">已掌握</el-button>
        <el-button type="primary">再看看</el-button>
      </span>
    </el-dialog>
    <writetime type="1" :tz_time="tz_time"></writetime>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import base from "../../libs/base";
export default {
  data() {
    return {
      dialogVisible: false,
      type: 1,
      show: true,
      flag: false,
      params: {
        username: "",
        classid: "",
        page: 1,
      },
      total: 0,
      ledgDeta: {},
      active: 0,
      activeIndex: 0,
      practicalList: [],
      time: null,
      socket: null, //socket对象
      setinter: null, //循环发送消息（5秒）
      socketLoad: null, //socket连接成功
      lock: true, //禁止多次调用
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "../../static/images/test.jpg", //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      flag1: true,
      flag2: false,
      leveTime: null, //离开页面时间
      backTime: null, //返回页面时间
      begin_time: null,
      reset: true,
      popup_time: null,
      human_time: null,
      Socket_sta: null,
      Socket_end: null,
      screen: true, //切屏停止计时
      flags: false,
      flagss: false,
      checked: false,
      tz_time: 0,
      num: 1,
      timeString: 0, //当前进度时间
      timeOld: 0, //最长观看视频时间
      present: 1, // 1需要跳转指定位置
      practical: 0,
      practical_flag: true,
      copy_list: [],
      viode_str: 0, //视频播放开始时间
      gettiming_flag: true,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      details: (state) => state.details,
      classid: (state) => state.classid,
      url: (state) => state.url,
      school: (state) => state.school,
      studyType: (state) => state.studyType,
      human: (state) => state.human,
      human_src: (state) => state.human_src,
    }),
  },
  methods: {
    ...mapActions([
      "getVideoList",
      "getVideoAudioCallback",
      "getKnowledgeList",
      "getKnowledgeDetail",
      "getSetKnowledgeStatus",
      "getscreenCuttingDuration",
      "getfaceRecognition",
      "getactivityDetection",
      "getPictureUpload",
      "faceAuth",
    ]),
    onPlayerTimeupdate(player) {
      //记录播放时间

      this.practical += 0.26;

      // if (this.practical_flag) {
      //   this.practical_flag = false;
      //   setTimeout(() => {
      //     this.practical += 1;
      //     this.practical_flag = true;

      //   }, 1000);
      // }
      //判断是否看完，进行没看完进行续播和进度条限制 1.看完2.没看完
      if (this.copy_list[this.activeIndex].is_finished == 2) {
        //判断是否需要续播
        if (this.present == 1) {
          this.timeString = player.cache_.currentTime;
          this.present = 2;
          player.currentTime(this.timeOld);
          return;
        }
        //限制进度条
        // if (player.cache_.currentTime - this.timeString > 1) {
        //   if (player.cache_.currentTime >= this.timeOld) {
        //     this.timeString = this.timeOld;
        //     player.currentTime(this.timeOld);
        //     return;
        //   } else {
        //     this.timeString = player.cache_.currentTime;
        //     player.currentTime(this.timeString);
        //     return;
        //   }
        // }
        //记录播放进度和记录播放最长进度
        this.timeString = player.cache_.currentTime;
        if (this.timeString > this.timeOld) {
          this.timeOld = player.cache_.currentTime;
        }
      } else {
        this.timeString = player.cache_.currentTime;
        if (this.timeString > this.timeOld) {
          this.timeOld = player.cache_.currentTime;
        }
      }
    },
    onPlayerEnded() {
      let that = this;
      if (this.activeIndex == this.playerOptions.sources.length - 1) {
        this.getscreenCuttingDuration({
          username: that.userInfo.username,
          class_id: this.details.id, //班级id
          user_id: this.userInfo.id, //学员id
          video_time: this.practical.toFixed(0), //视频观看时长
          source: "1", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
          video_id: this.playerOptions.sources[this.activeIndex].id, //视频id
          is_finished: 1, //是否学完 1-完成 2-未完成
          last_viewing_time: this.timeOld.toFixed(0),
          sub_video_time: {
            video_time: 0, //视频时长
            video_close_time: this.timeOld.toFixed(0),
            video_start_time: this.viode_str.toFixed(0),
          }, //副表参数
        }).then((res) => {
          this.practical = 0;
        });
        return;
      }
      this.tapVideo(
        this.playerOptions.sources[this.activeIndex + 1],
        this.activeIndex + 1,
        "yes"
      );
    },
    goon() {
      this.flag2 = false;
      this.tz_time = 0;
    },
    openCamera() {
      this.$nextTick(() => {
        var video = this.$refs.video;
        this.video = video;
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({
              video: true,
            })
            .then(function (stream) {
              MediaStreamTrack =
                typeof stream.stop === "function"
                  ? stream
                  : stream.getTracks()[1];
              video.srcObject = stream;
              video.play();
            })
            .catch(function (err) {});
        } else if (navigator.getMedia) {
          navigator
            .getMedia({
              video: true,
            })
            .then(function (stream) {
              MediaStreamTrack = stream.getTracks()[1];
              video.src = window.webkitURL.createObjectURL(stream);
              video.play();
            })
            .catch(function (err) {});
        }
      });
    },
    inittime(time) {
      var s = "";

      var hour = time.split(":")[0];
      var min = time.split(":")[1];
      var sec = time.split(":")[2];

      s = Number(hour * 3600) + Number(min * 60) + Number(sec);

      return s;
    },
    //canvas中展示截取的图像
    getImg() {
      let that = this;
      var canvas = this.$refs.canvas;
      var context = canvas.getContext("2d");
      context.drawImage(this.video, 0, 0, 200, 150);
      var image = canvas.toDataURL("image/png");
      var timestamp = Date.parse(new Date()); //获取时间戳，防止图像重名
      var img = this.dataURLtoFile(image, timestamp + ".png"); //使用png的图像进行名称拼接

      // this.video.srcObject.getTracks()[0].stop();
      // return;
      var imgBase64;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function () {
        imgBase64 = this.result; //获取到base64格式图片

        that
          .getPictureUpload({
            username: that.userInfo.username,
            img: imgBase64,
          })
          .then((res) => {
            if (res.code == 200) {
              that
                .faceAuth({
                  username: that.userInfo.username, //用户名
                  image: res.data, //人脸图片
                })
                .then((ress) => {
                  if (ress.code == 200) {
                    that.$store.commit("STE_HUMAN", 0);
                    that.video.srcObject.getTracks()[0].stop();
                    that.flags = false;
                  } else {
                    that.video.srcObject.getTracks()[0].stop();
                    that.$router.push("/Layout/mynterpretation");
                  }
                });
            }
          });
      };
    },

    //图片转base64位
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var file = new File([u8arr], filename, { type: mime });
      return new File([u8arr], filename, { type: mime });
    },
    loadmore() {
      ++this.params.page;
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.getVideoList(this.params).then((res) => {
        let list2 = [];
        if (res.code == 200) {
          res.data.list.forEach((e) => {
            list2.push({
              ...e,
              src: e.url.indexOf("http") > -1 ? e.url : this.url + e.url,
              type: "",
            });
          });
          this.playerOptions.sources.push(...list2);
          this.copy_list = JSON.parse(
            JSON.stringify(this.playerOptions.sources)
          );
          console.log(this.playerOptions.sources, "this.playerOptions.sources");
          console.log(this.copy_list, " this.copy_list");
          loading.close();
          if (res.data.list.length != 15) {
            this.show = false;
          }
        } else {
          loading.close();
          this.show = false;
        }
      });
    },
    //更改实训考点状态
    getStatus() {
      this.getSetKnowledgeStatus({
        id: this.ledgDeta.id,
        username: this.userInfo.username,
        classid: this.classid,
        status: 2,
      }).then((res) => {
        this.$message({
          message: res.msg,
          type: res.code == 200 ? "success" : "cancel",
        });
        if (res.code == 200) {
          this.practicalList = this.practicalList.map((e) => {
            if (e.id == this.ledgDeta.id) {
              e.status = 2;
            }
            return e;
          });
          this.dialogVisible = false;
        }
      });
    },
    setClick(index, item) {
      if (this.studyType == 2 && index > 0) {
        this.$vux.toast.show({
          type: "cancel",
          text: "请购买课程",
        });
        return;
      }
      this.practicalList = this.practicalList.map((e) => {
        e.selected = false;
        return e;
      });
      item.selected = true;
      this.getKnowledgeDetail({
        username: this.userInfo.username,
        classid: this.classid,
        id: item.id,
      }).then((res) => {
        if (res.code == 200) {
          this.dialogVisible = true;
          this.ledgDeta = { ...res.data, ...item };
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    //视频选集
    tapVideo(item, index, status, type) {
      if (type == 1) {
        if (index == this.activeIndex) return;
      }
      this.active = index;
      let that = this;
      if (this.studyType == 2 && index > 0) {
        this.$vux.toast.show({
          type: "cancel",
          text: "请购买课程",
        });
        return;
      }
      if (status == "yes") {
        this.copy_list[this.activeIndex].is_finished = 1;
      } else {
        this.copy_list[this.activeIndex].viewed_time = this.timeOld;
      }
      this.getscreenCuttingDuration({
        username: that.userInfo.username,
        class_id: this.details.id, //班级id
        user_id: this.userInfo.id, //学员id
        video_time: this.practical.toFixed(0), //视频观看时长
        source: "1", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
        video_id: this.playerOptions.sources[this.activeIndex].id, //视频id
        is_finished: status == "yes" ? 1 : 2, //是否学完 1-完成 2-未完成
        last_viewing_time: this.timeOld.toFixed(0),
        sub_video_time: {
          video_time: 0, //视频时长
          video_close_time: this.timeOld.toFixed(0),
          video_start_time: this.viode_str.toFixed(0),
        }, //副表参数
      });
      this.activeIndex = index;
      // if (this.copy_list[this.activeIndex].is_finished == 1) {
      //   this.playerOptions.playbackRates = [0.7, 1.0, 1.5, 2.0];
      // } else {
      //   this.playerOptions.playbackRates = [1.0];
      // }
      if (this.$refs.videoPlayer) {
        setTimeout(() => {
          this.$refs.videoPlayer.player.pause();
          this.$refs.videoPlayer.player.src(item.url);
          this.$refs.videoPlayer.player.play();
        }, 100);
        this.getLog(item);
      }
    },
    //记录
    getLog(item) {
      this.getVideoAudioCallback({
        username: this.userInfo.username,
        courseware: item.courseware,
        kindof: 3,
        id: item.id,
      });
    },
    getVideo() {
      //视频
      this.getVideoList(this.params).then((res) => {
        if (res.code == 200) {
          var list = [];
          res.data.list.forEach((e) => {
            list.push({
              ...e,
              src: e.url.indexOf("http") > -1 ? e.url : this.url + e.url,
              type: "",
            });
          });

          this.playerOptions.sources.push(...list);
          this.copy_list = JSON.parse(
            JSON.stringify(this.playerOptions.sources)
          );
          console.log(this.copy_list, "this.copy_list");
          if (res.data.list.length != 15) {
            this.show = false;
          }

          setTimeout(() => {
            if (this.params.page == 1) {
              if (this.playerOptions.sources[0].is_finished == 2) {
                // this.stop1();
                // this.playerOptions.playbackRates = [1.0];
                this.present = 1;
                this.timeOld = this.copy_list[this.activeIndex].viewed_time; //最长观看视频时间
                this.timeString = 0; //最长观看视频时间
                this.viode_str = this.copy_list[this.activeIndex].viewed_time;
              }
              this.tapVideo(this.playerOptions.sources[0], 0);
            }
          }, 500);

          this.total = res.data.allpage;
        } else {
          this.show = false;
          this.$vux.toast.show({
            type: "cancel",
            text: res.msg,
          });
          this.total = this.params.page;
        }
      });
    },
    init() {
      //实训考点
      this.getKnowledgeList({
        username: this.userInfo.username,
        classid: this.classid,
      }).then((res) => {
        if (res.code == 200) {
          this.practicalList = res.data;
        }
      });
    },
    socketInit() {
      this.socket = new WebSocket(base.socketUrl);

      this.Socket_sta = new Date();
      var that = this;
      this.socket.onopen = function () {
        //补回连接中的时间段
        that.Socket_end = new Date();
        var date3 = that.Socket_end.getTime() - that.Socket_sta.getTime();

        that.getscreenCuttingDuration({
          username: that.userInfo.username,
          user_id: that.userInfo.id,
          class_id: that.classid,
          video_time: (date3 / 1000).toFixed(0),
          source: 1,
        });

        that.socketLoad = true;
      };
    },
    sendSocketMsg() {
      this.time = setInterval(() => {
        this.lock = true;
        if (this.socketLoad && this.screen) {
          this.socket.send(
            JSON.stringify({
              type: 1,
              classId: this.details.id,
              userId: this.userInfo.id,
              source: 1,
            })
          );
        }
      }, 5 * 60 * 1000);
    },
    getdocument() {
      let that = this;
      if (document.hidden) {
        that.$refs.videoPlayer.player.pause();
      } else {
      }
    },
    dynamic() {
      let that = this;
      // let num = 60 * 1000;
      let num = 15 * 60 * 1000;
      //随机弹窗
      let str = Math.round(Math.random() * num);

      // this.$vux.toast.show({
      //   type: "success",
      //   text: `${str / 1000}秒回触发活跃度检查`,
      // });
      if (!this.flag1 || this.num > 2) return; // 停止回调
      that.popup_time = setTimeout(() => {
        that.flag = true;
        that.start_time = new Date(+new Date() + 8 * 3600 * 1000)
          .toJSON()
          .substr(0, 19)
          .replace("T", " ");
        if (!this.flag1 || this.num > 2) return; // 停止回调
        that.dynamic_time = setTimeout(() => {
          that.getactivityDetection({
            username: that.userInfo.username,
            id: that.userInfo.id,
            status: 2,
            source: 1,
            start_time: that.start_time,
            respond_time: null,
          });
          if (that.num == 2) {
            that.flag2 = true;
            that.socketLoad = false;
            that.tz_time = new Date().getTime();
          }
          that.num = that.num + 1;
          that.flag = false;
          if (that.num > 2) return; // 停止回调
          that.dynamic();
        }, 30000);
      }, str);
    },
    normal1() {
      if (this.checked == false) {
        this.$message({
          message: "请勾选协议",
          type: "warning",
        });
      } else {
        this.flagss = false;
        this.openCamera();
        this.flags = true;

        return;
      }
    },
    normal() {
      clearTimeout(this.dynamic_time);
      clearTimeout(this.popup_time);
      this.tz_time = 0;
      this.flag1 = false;
      this.socketLoad = true;
      this.respond_time = new Date(+new Date() + 8 * 3600 * 1000)
        .toJSON()
        .substr(0, 19)
        .replace("T", " ");
      this.getactivityDetection({
        username: this.userInfo.username,
        id: this.userInfo.id,
        status: 1,
        source: 1,
        start_time: this.start_time,
        respond_time: this.respond_time,
      });
      this.flag = false;
    },
    stop1() {
      let that = this;
      setTimeout(() => {
        that.$nextTick(() => {
          that.$refs.videoPlayer.player.play();
          // let a = document.getElementsByClassName("vjs-progress-control")[0];
          // a.style.pointerEvents = "none";
        });
      }, 100);
    },
    play1() {
      let that = this;
      setTimeout(() => {
        that.$nextTick(() => {
          that.$refs.videoPlayer.player.play();
          // let a = document.getElementsByClassName("vjs-progress-control")[0];
          // a.style.pointerEvents = "auto";
        });
      }, 100);
    },
  },
  mounted() {
    let that = this;
    this.params.username = this.userInfo.username;
    this.params.classid = this.classid;
    this.getVideo();
    this.init();
    // setTimeout(() => {
    //   that.socketInit();
    //   that.sendSocketMsg();
    // }, 500);

    document.addEventListener("visibilitychange", that.getdocument, false);
    //进入手机页面30分钟后执行随机弹窗任务，点击重置随机弹窗任务
    this.begin_time = setTimeout(() => {
      this.dynamic();
    }, 30 * 60 * 1000);
    // }, 60 * 1000);
    //判断是否需要人脸识别 human:10需要
    if (this.details.face == 1) {
      if (this.human == 10) {
        this.human_time = setTimeout(() => {
          that.flagss = true;
        }, 30 * 60 * 1000);
      }
    }
  },

  beforeDestroy() {
    let that = this;
    this.getscreenCuttingDuration({
      username: that.userInfo.username,
      class_id: this.details.id, //班级id
      user_id: this.userInfo.id, //学员id
      video_time: this.practical.toFixed(0), //视频观看时长
      source: "1", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
      video_id: this.playerOptions.sources[this.activeIndex].id, //视频id
      is_finished: 2, //是否学完 1-完成 2-未完成
      last_viewing_time: this.timeOld.toFixed(0),
      sub_video_time: {
        video_time: 0, //视频时长
        video_close_time: this.timeOld.toFixed(0),
        video_start_time: this.viode_str.toFixed(0),
      }, //副表参数
    });
    //必须内存地址相同才能移出方法
    document.removeEventListener("visibilitychange", that.getdocument, false);

    this.flag1 = false;
    clearInterval(this.time);
    clearTimeout(this.human_time);
    clearTimeout(this.dynamic_time);
    clearTimeout(this.begin_time);
    // this.socket.close();
  },
  watch: {
    activeIndex(val, vals) {
      let that = this;
      this.timeString = 0;
      this.timeOld = 0;
      this.practical = 0;
      this.viode_str = 0;
      if (this.copy_list[val].is_finished != 2) {
        setTimeout(() => {
          that.viode_str = 0;
          that.play1();
        }, 200);
      } else {
        setTimeout(() => {
          that.present = 1;
          that.timeOld = that.copy_list[val].viewed_time; //最长观看视频时间
          that.viode_str = that.copy_list[val].viewed_time;
          that.timeString = 0; //最长观看视频时间

          that.stop1();
        }, 200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-js .vjs-icon-placeholder {
  width: 100%;
  height: 100%;
  display: block;
}
.human_mask {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  .popup {
    position: relative;
    width: 414px;
    height: 453px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(147, 147, 147, 0.5);
    border-radius: 15px;
    .title1 {
      padding-top: 30px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #008bd5;
      line-height: 33px;
    }
    .head {
      display: block;
      margin: 0 auto;
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
    // .head_1 {
    //   position: absolute;
    //   width: 100px;
    //   height: 100px;
    //   left: 0;
    //   top: 0;
    // }
    .dian {
      border-top: 2px solid #eaeaea;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50px;
      width: 100%;
      line-height: 50px;
      margin-top: 15px;
      text-align: center;

      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      cursor: pointer;
    }
  }
}
.mask {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  .imgs {
    display: block;
    width: 300px;
    margin: 0 auto;
    vertical-align: middle;
  }
  .popup {
    position: relative;
    z-index: 100;
    width: 414px;
    height: 377px;
    background: #ffffff;
    border-radius: 16px;
    .title1 {
      padding-top: 30px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #008bd5;
      line-height: 33px;
    }
    .content1 {
      margin: 30px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .dian {
      border-top: 2px solid #eaeaea;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50px;
      width: 100%;
      line-height: 50px;
      margin-top: 15px;
      text-align: center;

      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      cursor: pointer;
    }
  }
}
.mask1,
.stop_mask {
  position: fixed;
  z-index: 99;

  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .imgs {
    display: block;
    width: 200px;
    margin: 0 auto;
    vertical-align: middle;
  }
  .img {
    width: 40px;
    height: 40px;
    display: block;
    margin: 15px auto;
  }
  .popup {
    position: relative;
    z-index: 100;
    width: 227px;
    height: 143px;
    background: #ffffff;
    border-radius: 16px;
    .title1 {
      text-align: center;
      padding: 20px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      line-height: 26px;
      span {
        font-size: 18px;
      }
      .span {
        font-weight: 500;
        font-size: 16px;
        color: #000;
      }
    }
    .xian {
      width: 100%;
      height: 2px;
      background-color: #eaeaea;
    }
    .dian {
      cursor: pointer;
      margin-top: 15px;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
    }
  }
}
.loadmore {
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}
.title {
  margin-top: 68px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 26px;
  display: flex;
  .item {
    cursor: pointer;
    position: relative;
    margin-right: 52px;
  }
  .active {
    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      width: 108px;
      height: 4px;
      background: #137cfb;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.list {
  display: flex;
  flex-wrap: wrap;

  .active {
    color: #137cfb !important;
    border: 2px solid #137cfb !important;
    background: #f5fbff;
  }
  .item {
    cursor: pointer;
    position: relative;
    margin-bottom: 17px;
    margin-right: 18px;
    padding: 10px;
    box-sizing: border-box;
    width: 160px;
    height: 75px;
    background: #fff;
    border-radius: 8px;
    border: 2px solid #e7e7e7;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    overflow: hidden;
    line-height: 20px;

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .wc {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
